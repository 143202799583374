/* src/components/sell_page/sellreceipt/SellReceipt.css */

.sell-receipt-container {
    padding: 20px;
  }
  
  .sell-receipt {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .receipt-header,
  .customer-info,
  .products-table,
  .services-table,
  .total-amount,
  .receipt-footer {
    margin-bottom: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  table th,
  table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  table th {
    text-align: left;
  }
  
  .action-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  @media print {
    .action-buttons {
      display: none;
    }
  }