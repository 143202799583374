/* src/components/ChangePassword.css */

.change-password-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .input-container {
    margin-bottom: 15px;
  }
  
  .error-text {
    color: red;
  }
  
  .success-text {
    color: green;
  }
  
  .change-password-button {
    width: 100%;
    padding: 12px;
    background: linear-gradient(to right, #6a11cb, #2575fc);
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
  }

  .change-password-button:hover {
    background: linear-gradient(to right, #2575fc, #6a11cb);
  }