/* src/styles/CustomNavbar.css */
.nav-link-active {
    background-color: #0d6efd; /* Bootstrap primary blue color */
    color: white;
    border-top-left-radius: 8px; /* Curved top-left corner */
    border-top-right-radius: 8px; /* Curved top-right corner */
    font-size: 18px;
    transition: transform 0.2s ease-in-out; /* Smooth transition */
  }

.custom-navbar-padding {
  padding-top: 10px; /* Set your desired padding */
  padding-bottom: 0px; /* Set your desired padding */
}


/* Optionally, you can ensure that the font color remains white even when the Nav.Link is focused or hovered */
.nav-link-active:hover,
.nav-link-active:focus {
  color: white !important;
}

.active-nav-label {
  background-color: #0d6efd; /* Bootstrap primary blue color */
  color: white;
  padding: 5px 10px; /* Padding for better appearance */
  border-top-left-radius: 8px; /* Curved top-left corner */
  border-top-right-radius: 8px; /* Curved top-right corner */
  font-size: 1rem;
  font-weight: bold;
  display: inline-block; /* Ensure it behaves like a block element */
}

@media (min-width: 768px) {
  /* Hide the label on screens wider than 768px (md and up) */
  .active-nav-label {
    display: none;
  }
}