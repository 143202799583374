/* src/app.css */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Table */

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

table, th, td {
  border: 1px solid black;
}

th, td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}


/* Add border-radius to the table */
.table {
  border-radius: 10px; 
  overflow-x: auto;
  width: 100%;
  max-width: 100%; /* Ensure the table doesn't exceed 100% of its container width */
  margin: 1px 0;
  /*border-collapse: separate; */
}

/* Keep the table header style */
.table th {
  background-color: rgb(65, 151, 255) !important;
  color: white !important;
  text-align: center;
}


/* Add sticky positioning for the table header */
.table thead th {
  position: sticky;
  top: 0; /* Sticks the header to the top */
  background-color: rgb(65, 151, 255); /* Keep the background color for the sticky header */
  color: white; /* Keep the text color white */
  z-index: 2; /* Ensure the header is on top of other content */
}

.table-container {
  margin-top: 0px; /* Remove any margin on the table container */
  position: relative; /* Make the table container positioned */
  overflow-x: auto; /* Allow horizontal scrolling of the table if necessary */
  overflow-y: auto; /* Enable vertical scrolling */
  margin-bottom: 20px; /* Add some margin to avoid overlapping the pagination */
}
/* Optionally, add a subtle border to the table 
.table {
  border: 1px solid #ddd;
}
*/

.no-border-table {
  border: none;
}

.no-border-table th, .no-border-table td {
  border: none;
}



/* Form */

/* Make the filter form text box borders darker */
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="search"],
textarea {
  border: 1px solid #b1b0b0; /* Set a darker border color (e.g., dark gray or black) */
  padding: 8px; /* Optional: Add padding for better spacing */
  border-radius: 4px; /* Optional: Add a slight border radius */
}
select.form-select.custom-select-border {
  border-color: #b1b0b0; /* Replace with your desired darker color */
  border-width: 1px;  /* Adjust border width if needed */
  border-style: solid; /* Ensure the border style is set */
}

.filter-form-wrapper input[type="text"]:focus {
  border-color: #000; /* Make the border even darker when focused */
  outline: none; /* Remove the default outline */
}


/* Keep the pagination styling */
.pagination {
  display: flex;
  justify-content: center;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.pagination li a {
  color: #007bff;
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 4px;
}

.pagination li a:hover {
  background-color: #f1f1f1;
}

.pagination .active a {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.pagination .disabled a {
  color: #ccc;
}

.filter-form-wrapper {
  margin-top: 1px; /* Adjust this value as needed */
  margin-bottom: 1px; /* Reduce the bottom margin to 5px */
}

body, html {
  overflow-x: hidden; /* Prevent the page from scrolling horizontally */
}


/* for catsqltable typesqltable ... */
.btn-circle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  text-align: center;
}

.table-active {
  --bs-table-color-state: #000000;
  --bs-table-bg-state: rgba(20, 99, 225, 0.2);
}

tr.selected-row {
  --bs-table-color-state: #000000;
  --bs-table-bg-state: rgba(20, 99, 225, 0.2);
}

.modal-dialog.custom-modal-width400  {
  --bs-modal-width: 400px;
}

/* Target the specific table using the custom class */
.sell-job-table tfoot .footer-cell {
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: none;
}

/* Custom styles for the editColumn footer */
.footer-cell-editcolumn {
  border: none; /* Example: Remove border */
  background-color: #f9f9f9; /* Example: Add a background color */
}

table.table-striped.sell-job-table {
  --bs-table-border-color: #fff;
}