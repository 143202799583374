.scanner-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .video-container {
    position: relative;
    width: 300px;
    margin: 0 auto;
  }
  
  .scanner-video {
    width: 100%;
    height: auto;
  }
  
  .overlay-line {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: rgba(255, 0, 0, 0.8); /* Red line for better visibility */
    z-index: 2;
    transform: translateY(-50%);
  }