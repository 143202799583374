/* Main.css */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.hamburger-button {
  width: 20px;
  height: 35px;
  font-size: 22px;  /* Adjust the font size if necessary */
  border: none;
  background: none;
  background-color: #007bff; /* Add a background color */
  color: white; /* Ensure the hamburger icon is visible */
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}

.filter-form-wrapper {
  flex-grow: 1;
  position: relative;
}

.filter-form-wrapper .row .col {
  padding-left: 2px;
  padding-right: 2px;
}

.filter-form-wrapper .form-control {
  margin-bottom: 0;
}

.custom-sidebar {
  width: 300px; /* Set the desired width */
  max-width: 300px; /* Prevent the sidebar from expanding beyond this width */
}

