.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .login-box {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .login-title {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .input-container {
    margin-bottom: 20px;
  }
  
  .input-container input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    background: linear-gradient(to right, #6a11cb, #2575fc);
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .login-button:hover {
    background: linear-gradient(to right, #2575fc, #6a11cb);
  }
  
  .error-text {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
  }